import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';

import {User} from 'src/app/interfaces/user.interface';
import {CoreService} from 'src/app/services/core/core.service';
import {AlertDialogComponent} from 'src/app/standalone/alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() menuToggled = new EventEmitter<boolean>();
  user!: User;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private authService: CoreService,
  ) {}

  ngOnInit() {
    this.authService.user$.subscribe({
      next: user => (this.user = user as User),
    });
  }

  alertDialog(): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '260px',
      data: {
        title: 'Logout',
        actionName: 'Do you want to Logout?',
        buttonName: 'Logout',
      },
    });
    dialogRef.componentInstance.decision.subscribe({
      next: (flag: boolean) => {
        if (!flag) return;

        // sessionStorage.clear();
        // sessionStorage.clear();
        // this.router.navigate(['/login']);
        this.authService.logout().subscribe({
          next: () => {
            sessionStorage.clear();
            sessionStorage.clear();
            this.router.navigate(['/login']);
          },
          error: error => {
            console.error(error);
          },
        });
      },
    });
  }
}
